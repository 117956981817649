<template>
  <div>
    <el-card class="box-card" v-loading="loading">
      <div slot="header" class="clearfix flex_i">
        <span class="title_cadr_block">{{ $t("message.owner_clinic") }}</span>
        <div class="d-flex" style="float: right; padding: 3px 0">
          <el-button
            v-if="!model"
            size="small"
            class="p-2"
            type="primary"
            @click="drawerCreate = true"
            icon="el-icon-plus"
            circle
          ></el-button>
          <el-button
            :disabled="!model"
            size="small mr-2"
            class="p-2"
            type="success"
            @click="clickUpdate(model)"
            icon="el-icon-edit"
            circle
          ></el-button>
          <el-button
            :disabled="!model"
            size="small"
            class="p-2"
            @click="destroy(model)"
            type="danger"
            icon="el-icon-delete"
            circle
          ></el-button>
        </div>
      </div>
      <el-card
        class="mt-4 a4"
        shadow="always"
        style="text-align: center; min-height: 100%"
      >
        <img v-if="!model" src="/img/LogoDark.png" height="50%" width="50%" />
        <img v-else :src="url + '/' + model.image" height="50%" width="50%" />
      </el-card>

      <div
        class="info-click"
        style="width: 40%; text-align: center; margin: auto"
        v-if="model"
      >
        <div class="l-i-c">
          {{ model.address }}
          <div class="link-klink">
            <div style="color: red">{{ model.website }} {{ model.email }}</div>
          </div>
        </div>
        <div class="l-i-c">
          <div
            class="i-ilor"
            v-for="(phone_number, index) in phone_numbers"
            :key="index"
          >
            <span>{{ index }} :</span>
            <b v-for="(item, i) in phone_number" :key="i">{{
              item.phone_number
            }}</b>
          </div>
        </div>
      </div>
    </el-card>

    <el-drawer
      title="Новый тег"
      :visible.sync="drawerCreate"
      size="60%"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
    >
      <div>
        <crm-create @c-close="closeDrawer"></crm-create>
      </div>
    </el-drawer>
    <el-drawer
      size="60%"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
        :selected="selectedModel"
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
      >
      </crm-update>
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import { mapGetters, mapActions } from "vuex";
import CrmUpdate from "./components/crm-update";
import drawer from "@/utils/mixins/drawer";
import { i18n } from "@/utils/i18n";

export default {
  mixins: [drawer],
  name: "letterhead",
  data() {
    return {
      url: process.env.VUE_APP_URL_DOCS,
      logo: [],
      logoimg: [],
      loading: false,
      url: process.env.VUE_APP_URL_DOCS,
      selectedModel: "",
      drawerUpdate: false,
      reopenUpdate: false,
      drawerCreate: false,
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
    };
  },
  components: { CrmCreate, CrmUpdate },
  computed: {
    ...mapGetters({
      model: "ownerClinic/model",
      phone_numbers: "ownerClinic/phone_numbers",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions({
      getOwnerClinic: "ownerClinic/getOwnerClinic",
      delete: "ownerClinic/destroy",
    }),
    listChanged() {
      this.reloadList = true;
    },
    closeDrawer(drawer) {
      this[drawer.drawer] = false;
      if (this.reloadList === true) {
        this.fetchData();
      }
      if (this[drawer.emptyModel]) {
        this.emptyModel();
      }
    },
    reloadIfChanged(modal) {
      this[modal] = false;
      if (this.reloadList === true) {
        this.fetchData();
        this.afterFetchData();
      }
    },
    clickUpdate(model) {
      this.fetchData();
      this.drawer.update.status = true;
      this.selectedModel = model;
    },
    async fetchData() {
      this.drawer.update.status = false;
      this.drawerCreate = false;
      this.getOwnerClinic();
    },
    fetchDelete(model) {
      this.selectedModel = model;
    },
    destroy(obj) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.delete(obj.id)
            .then((res) => {
              this.$alert(res);
              this.loading = false;
              this.fetchData();
              this.logo = [];
              this.logoimg = [];
            })
            .catch((err) => {
              this.$alert(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.cancel"),
          });
        });
    },
    afterFetchData() {
      this.reloadList = false;
    },
    emptyModel() {},
  },
};
</script>
<style lang="scss" scoped>
.my_letterhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;

  img {
    width: 300px;
  }

  ul {
    text-align: right;
    list-style: none;
    line-height: 23px;

    li {
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
    }
  }
}
.title_cadr_block {
  padding-top: 10px;
  font-size: 16px;
  font-weight: 700;
}
.flex_i {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.flex_i::after {
  content: none;
}

.a4 {
  width: 220mm;
  margin: auto;
}
.info-click {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.l-i-c {
  width: 40%;
  font-size: 13px;
}
.i-ilor {
  display: flex;
  justify-content: space-between;
}
.i-ilor span {
  width: 75px;
}
</style>
