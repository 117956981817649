<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <div class="grid-content bg-purple">
            <div style="display: inline-block" class="title_h">
              <h5>{{ $t("message.new_owner_clinic") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                size="medium"
                :loading="save_loading"
                :disabled="save_loading ? true : false"
                type="success"
                @click="saveData"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="columns.address.title" prop="address">
            <crm-input v-model="form.address"> </crm-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="columns.web_site.title" prop="web_site">
            <crm-input v-model="form.website"> </crm-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="columns.email.title" prop="email">
            <crm-input v-model="form.email"> </crm-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item :label="columns.image.title" prop="image">
            <el-upload
              class="upload-demo w-100"
              action="/"
              ref="upload"
              :limit="1"
              :on-change="updateImageList"
              :on-remove="handleRemove"
              list-type="picture-card"
              :file-list="imageList"
              :auto-upload="false"
            >
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <div style="display: inline-block" class="title_h">
          <h5>{{ $t("message.phone_numbers") }}</h5>
        </div>
        <hr />
        <div v-for="(phone_number, index) in phone_numbers" :key="index">
          <el-col :span="9">
            <el-form-item :label="$t('message.type_phone')">
              <el-input v-model="phone_number.type_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item :label="$t('message.phone_number')">
              <el-input
                v-model="phone_number.phone_number"
                v-mask="'+999999999999'"
                placeholder="998(__) ___-__-__"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="Касса печать">
              <el-checkbox
                style="padding-right: 20%; padding-left: 40%"
                v-model="phone_number.payment_print"
              ></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button
              size="small"
              icon="el-icon-delete"
              style="height: 38px; margin-top: 22px"
              type="danger"
              @click="clear()"
              @click.prevent="removePhoneNumber(index)"
              >{{ $t("message.clear") }}</el-button
            >
          </el-col>
        </div>
      </el-row>
      <div
        class="plusic mt-3"
        @click="addPhoneNumber()"
        style="height: 10%; width: 5%"
      >
        <i class="el-icon-plus"></i>
      </div>
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  name: "createForm",
  data() {
    return {
      save_loading: false,
      imageList: [],
      phone_numbers: [
        {
          phone_number: "",
          type_name: "",
          payment_print: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      columns: "ownerClinic/columns",
      rules: "ownerClinic/rules",
    }),
  },
  methods: {
    ...mapActions({
      save: "ownerClinic/store",
      update: "ownerClinic/update",
      remove: "ownerClinic/destroy",
    }),
    handleRemove(file) {
      this.imageList = [];
    },
    updateImageList(file) {
      this.imageList = [];
      this.imageList.push({ url: file.url, raw: file.raw });
    },
    addPhoneNumber() {
      this.phone_numbers.push({
        phone_number: "",
        type_name: "",
        payment_print: false,
      });
    },
    removePhoneNumber(index) {
      this.phone_numbers.splice(index, 1);
    },
    saveData() {
      this.save_loading = true;

      let form = new FormData();
      for (const key in this.imageList) {
        if (this.imageList.hasOwnProperty(key)) {
          const element = this.imageList[key];
          form.append(`image`, element.raw);
        }
      }
      this.phone_numbers.forEach((value, index) => {
        value.phone_number.replaceAll("_", "");
      });
      for (const key in this.phone_numbers) {
        if (this.phone_numbers.hasOwnProperty(key)) {
          const element = this.phone_numbers[key];

          for (const el_key in element) {
            if (element.hasOwnProperty(el_key)) {
              const element_in = this.phone_numbers[key][el_key];
              form.append(`phone_numbers[${key}][${el_key}]`, element_in);
            }
          }
        }
      }
      for (var key in this.form) {
        if (this.form[key] != null) {
          form.append(key, this.form[key]);
        } else {
          form.append(key, "");
        }
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.save(form)
            .then((res) => {
              this.form = {};
              this.save_loading = false;
              this.resetForm("form");
              this.$parent.$parent.listChanged();
              this.$alert(res);
              this.imageList = [];
            })
            .catch((err) => {
              this.form = {};
              this.save_loading = false;
              this.resetForm("form");
              this.$alert(err);
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
  },
};
</script>
<style lang="scss">
.naw_Editor {
  .mce-panel {
    border: 0 solid #c5c5c55e;
  }
}
.block_Editor_Naw {
  padding: 20px;
}
</style>